.lobbyContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/lobby/lobbyBg.png');
}

.lobbyContainer .logout {
  border: none;
  outline: none;
  border-radius: 200px;

  padding: 10px 20px;
  margin: 1% auto 10px 1%;

  background-color: #00666F;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Nokia-Bold;

  z-index: 10;

  cursor: pointer;
}

.lobbyContainer  .logos {
  width: 35vw;
  height: calc(35vw / 6.58793);

  margin: 10px auto 2% 3%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/lobby/logosStream.png');
}

/* -------------------------------------------- */

.lobbyContainer .sections {
  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

/* width and height */
.lobbyContainer .sections::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.lobbyContainer .sections::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.lobbyContainer .sections::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}

/* Handle on hover */
.lobbyContainer .sections::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

/* --------------------------- */

.lobbyContainer .sections .principal {
  width: 40%;

  margin: 30vh auto auto auto;

  display: flex;
  flex-flow: column;
}

.lobbyContainer .sections .principal .title {
  width: 100%;
  display: flex;
  flex-flow: column;

  margin: auto;
}

.lobbyContainer .sections .principal .title h1 {
  width: calc(100% - 20px);
  text-align: start;
  font-family: Nokia-Bold;
  color: #206068;
  font-size: 2vw;
  line-height: 2vw;
  letter-spacing: .23vw;
  margin: auto;
}

.lobbyContainer .sections .principal .title h2 {
  width: calc(100% - 20px);
  text-align: start;
  font-family: Nokia-Extra-Bold;
  color: #D87233;
  font-size: 2.8vw;
  line-height: 2.8vw;
  letter-spacing: .23vw;
  margin: auto;
}

.lobbyContainer .sections .principal .separator {
  width: 70vw;
  height: 2px;

  margin: 3% 0%;

  background-color: #206068;

  z-index: 5;
}

.lobbyContainer .sections .principal .mainRoom {
  border: none;
  outline: none;
  border-radius: 200px;

  padding: 10px 4vw;
  margin: 20px auto auto 10px;

  background-color: #00666F;
  border-bottom: .5vw solid #00232e;
  box-shadow: 7px 7px 15px #555;
  color: #FFF;
  font-family: Nokia-Bold;
  font-size: 2vw;

  z-index: 10;

  cursor: pointer;
}

/* ======================================= */

.lobbyContainer .sections .dataCont {
  width: 50%;
  height: 100%;

  margin: auto auto auto 0;

  display: flex;
  flex-flow: column;
}

.lobbyContainer .sections .dataCont .agenda {
  width: 90%;
  height: 50%;

  margin: auto auto 0 auto;

  display: flex;
  flex-wrap: wrap;
}

/* =================================================== */

.lobbyContainer .sections .dataCont .speakers {
  width: 80%;
  height: 50%;

  margin: auto auto auto 5%;

  display: flex;
}

/* ============================================= */

.lobbyContainer .modalContainer {
  position: fixed;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .5);

  display: flex;

  z-index: 10000;
}

.lobbyContainer .modalContainer .scrollCont {
  position: relative;

  min-width: 50vw;
  max-height: 80vh;

  margin: auto;

  overflow-x: hidden;
  overflow-y: auto;

  display: flex;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

/* width and height */
.lobbyContainer .modalContainer .scrollCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.lobbyContainer .modalContainer .scrollCont::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.lobbyContainer .modalContainer .scrollCont::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}

/* Handle on hover */
.lobbyContainer .modalContainer .scrollCont::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.lobbyContainer .modalContainer .modalContent {
  width: 90%;

  margin: auto;
  padding: 10px 2vw;

  background-color: #74BC1F;
  border-radius: 2vw;

  display: flex;
  flex-flow: row;

  text-transform: uppercase;
}

.lobbyContainer .modalContainer .modalContent h2 {
  width: 50%;
  color: #FFF;
  font-family: Nokia-Extra-Bold;
  font-size: 2.5vw;
  line-height: 2.5vw;
}

.lobbyContainer .modalContainer .modalContent h2 span {
  width: 40%;
  color: #206068;
  font-family: Nokia-Extra-Bold;
  font-size: 1.6vw;

  margin: auto;
}

.lobbyContainer .modalContainer .modalContent h3 {
  width: 60%;
  color: #FFF;
  font-family: Nokia-Extra-Bold;
  font-size: 2.2vw;
  line-height: 2.2vw;

  margin: auto 10px;
}

.lobbyContainer .modalContainer .modalContent h3 span {
  width: 50%;
  color: #206068;
  font-family: Nokia-Extra-Bold;
  font-size: 3vw;

  margin: auto;
}

.lobbyContainer .modalContainer .close {
  position: absolute;
  top: 35vh;
  right: 24vw;

  background-color: transparent;
  border: none;

  font-family: Nokia-Extra-Bold;
  color: #FFF;
  font-size: 1.8vw;

  cursor: pointer;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .lobbyContainer .sections {
    display: flex;
    flex-flow: column;
  }

  .lobbyContainer  .logos {
    margin: 10px auto 2% 3%;

    width: 45vw;
    height: calc(45vw / 6.58793);
  }

  .lobbyContainer .sections .principal {
    width: 80%;
    min-height: 30vh;
    margin: 30px auto;
  }

  .lobbyContainer .sections .principal .title h1 {
    font-size: 4vw;
    line-height: 4vw;
    letter-spacing: .6vw;
  }

  .lobbyContainer .sections .principal .title h2 {
    width: calc(100% - 20px);
    font-size: 4.8vw;
    line-height: 4.8vw;
    letter-spacing: .6vw;
  }

  .lobbyContainer .sections .principal .mainRoom {
    padding: 10px 5vw;
    margin: 20px auto auto 10px;
    font-size: 3vw;
  }

  /* ------------------------------ */

  .lobbyContainer .sections .dataCont {
    width: 90%;
    min-height: max-content;
    height: max-content;

    margin: auto;
  }

  .lobbyContainer .sections .dataCont .agenda {
    width: 90%;
    min-height: max-content;
    height: auto;

    margin: auto auto 30px auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lobbyContainer .sections .dataCont .speakers {
    width: 90%;
    height: 40vh;
    min-height: 500px;

    margin: auto auto 20px auto;

    display: flex;
  }

}

@media (max-width: 768px) {
  .lobbyContainer  .logos {
    width: 90vw;
    height: calc(90vw / 6.58793);

    margin: 10px auto;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../img/lobby/logosStream.png');
  }

  .lobbyContainer .sections .dataCont {
    width: 90%;

    margin: auto;
  }

  .lobbyContainer .modalContainer .scrollCont {
    min-width: 80vw;
    max-height: 80vh;
  }

  .lobbyContainer .modalContainer .modalContent h2 {
    font-size: 4.5vw;
    line-height: 4.5vw;
  }

  .lobbyContainer .modalContainer .modalContent h2 span {
    font-size: 2.6vw;
  }

  .lobbyContainer .modalContainer .modalContent h3 {
    font-size: 4vw;
    line-height: 4vw;
  }

  .lobbyContainer .modalContainer .modalContent h3 span {
    font-size: 4vw;
  }

  .lobbyContainer .modalContainer .close {
    position: absolute;
    top: 42vh;
    right: 5vw;
    font-size: 4.8vw;
  }
}

@media (max-width: 640px) {
  .lobbyContainer .sections .principal {
    width: 90%;
    min-height: 30vh;
    margin: 30px auto;
  }

  .lobbyContainer .sections .principal .title h1 {
    font-size: 6vw;
    line-height: 6vw;
    letter-spacing: .6vw;
  }

  .lobbyContainer .sections .principal .title h2 {
    width: calc(100% - 20px);
    font-size: 6vw;
    line-height: 6vw;
    letter-spacing: .6vw;
  }

  .lobbyContainer .sections .principal .mainRoom {
    padding: 10px 7vw;
    margin: 20px auto auto 10px;
    font-size: 4vw;
  }
}
.ChatContainer {
  width: 100%;
  height: 60vh;
  max-height: 60vh;

  margin: 0px auto 20px auto;
  padding: 0;

  display: flex;
  flex-flow: column;
}

.ChatContainer .chat {
  position: relative;

  width: 100%;
  height: 100%;

  margin: auto;
  padding: 0px;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #206068 transparent;
}

/* width and height */
.ChatContainer .chat::-webkit-scrollbar {
  width: 10px; height: 6px;
}

/* Track */
.ChatContainer .chat::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.ChatContainer .chat::-webkit-scrollbar-thumb {
  background: #206068;
}

/* Handle on hover */
.ChatContainer .chat::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.ChatContainer .chat .chatBuffer {
  width: 100%;
  height: 100%;

  margin: 0;

  background-color: #EEE;
}

.ChatContainer .chat .chatBuffer .chatCard {
  width: 95%;
  padding: 2.5%;
  background-color: #F2F2F2;
  border: none;
  border-bottom: 1px solid #CCC;
  margin: auto;
  text-align: start;

  color: #206068;
  font-weight: 600;
  font-size: 1vw;
  font-family: Nokia-Regular;

  overflow: auto;

  display: flex;
}

.ChatContainer .chat .chatBuffer .chatCard .avatarCont,
.ChatContainer .chat .chatBuffer .chatOwnCard .avatarCont {
  width: 40px;
  height: 40px;
  min-width: 40px;

  margin: auto 10px auto 0px;

  background-color: #00232e;
  border-radius: 100px;

  color: #FFF;
  font-family: Nokia-light;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ChatContainer .chat .chatBuffer .chatCard .texts,
.ChatContainer .chat .chatBuffer .chatOwnCard .texts {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.ChatContainer .chat .chatBuffer .chatCard .chatUsername {
  margin: 0px;
  font-size: .9vw;
  font-family: Nokia-Bold;
  text-transform: lowercase;
  text-transform: capitalize;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ChatContainer .chat .chatBuffer .chatCard .chatUsername .time,
.ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername .time {
  color: #999;
  font-family: Nokia-light;
}

.ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername .time {
  color: #206068;
  font-family: Nokia-light;
}

.ChatContainer .chat .chatBuffer .chatOwnCard {
  width: 95%;

  margin: 0px;
  padding: 2.5%;

  border: none;
  border-bottom: 1px solid #AAA;

  background-color: #FFF;
  color: #206068;
  font-size: 1vw;
  text-align: start;
  font-family: Nokia-Regular;

  overflow: auto;

  display: flex;
}

.ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
  width: 100%;
  margin: auto;
  font-size: .9vw;
  font-family: Nokia-Bold;
  text-transform: lowercase;
  text-transform: capitalize;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ChatContainer .chat .chatBuffer .chatCard .chatMessage {
  margin: 10px auto 5px auto;
  font-weight: 300;
  font-size: .9vw;
  text-align: start;
  width: 100%;
}

.ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
  width: 100%;
  margin: 10px auto 5px auto;
  font-weight: 300;
  font-size: .9vw;
  text-align: start;
}

/* width and height */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar {
  width: 10px; height: 6px;
}

/* Track */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar-track {
  background: #FFF;
  border-radius: 10px;
}

/* Handle */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar-thumb {
  background: #F12A51;
  border-radius: 10px;
}

/* Handle on hover */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar-thumb:hover {
  background: #7a182c;
}

/* =============================================== */

.ChatContainer .chatForm {
  position: relative;

  width: 100%;
  min-height: 50px;

  margin: 1px auto auto 0px;

  display: flex;

  background-color: #206068;

  z-index: 100;
}

.ChatContainer .chatForm .chatInput {
  width: 75%;

  margin: auto 3%;
  padding: 5px 1vw;

  border: none;
  outline: none;

  text-align: start;
  font-size: 1vw;
  color: #111;
  font-family: Nokia-Regular;

  z-index: 10;
}

.ChatContainer .chatForm .chatInput::placeholder {
  color: #999;
}

.ChatContainer .chatForm .chatInput:disabled {
  background-color: #CCC;
}

.ChatContainer .chatForm .chatInputButton {
  z-index: 10;

  width: 10%;
  height: 40px;

  margin: auto;

  border: none;
  color: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #206068;

  cursor: pointer;
}

.ChatContainer .chatForm .chatInputButton svg {
  width: 90%;
  height: 90%;
}



/* ========================================== */
/* ========================================== */
/* ========================================== */
/* ========================================== */


@media (max-width: 1300px) {
  .ChatContainer .chatForm .chatInputButton {
    width: 13%;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatMessage {
    font-size: 1.2vw;
  }

  .ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 1.2vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatUsername {
    font-size: 1.2vw;
  }
  .ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 1.2vw;
  }
}

@media (max-width: 1024px) {

  .ChatContainer .chat .chatBuffer .chatCard {
    font-size: 2.3vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatMessage {
    font-size: 2vw;
  }

  .ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 2vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatUsername {
    font-size: 2vw;
  }
  .ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 2vw;
  }

  .ChatContainer .chatForm .chatInput {
    text-align: start;
    font-size: 2vw;
    color: #F12A51;
  }

  .ChatContainer .chatForm .chatInputButton {
    width: 6%;
  }
}

@media (max-width: 768px) {
  .ChatContainer .chatForm .chatInputButton {
    width: 8%;
  }
}

@media (max-width: 640px) {
  .ChatContainer .chat .chatBuffer .chatCard {
    font-size: 3.3vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatMessage {
    font-size: 3vw;
  }

  .ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 3vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatUsername {
    font-size: 3vw;
  }
  .ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 3vw;
  }

  .ChatContainer .chatForm .chatInput {
    text-align: start;
    font-size: 3.2vw;
    color: #F12A51;
  }

  .ChatContainer .chatForm .chatInputButton {
    width: 12%;
  }
}
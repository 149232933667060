/* Nokia FONTS */

@font-face {
  font-family: Nokia-Bold;
  src: url('./NokiaPureHeadline-Bold.woff');
}

@font-face {
  font-family: Nokia-Extra-Bold;
  src: url('./NokiaPureHeadline-ExtraBold.woff');
}

@font-face {
  font-family: Nokia-light;
  src: url('./NokiaPureHeadline-Light.woff');
}

@font-face {
  font-family: Nokia-Ultra-light;
  src: url('./NokiaPureHeadline-UltraLight.woff');
}

@font-face {
  font-family: Nokia-Regular;
  src: url('./NokiaPureHeadline-Regular.woff');
}
.speakerCont {
  width: 100%;
  height: 35vh;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

.speakerCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.speakerCont::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.speakerCont::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}
.speakerCont::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

/* ------------------------------------------ */

.speakerCont form {
  width: auto;
  display: flex;
  margin: 10px;
}

.speakerCont form .buttonRoom {
  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  display: flex;
}

.speakerCont form .buttonRoom:hover {
  transform: scale(1.01);
}

.speakerCont .speaker {
  width: 130px;

  margin: 10px;

  border-radius: 5px;
}

.speakerCont form .buttonRoom .speaker .image {
  position: relative;

  width: 100%;
  height: 150px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFF;

  border-radius: 8px;
  box-shadow: 5px 5px 10px #777;
}

.speakerCont form .buttonRoom .speaker .image .title {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  background-color: #00666F;
  border-radius: 0px 0px 7px 7px;

  text-align: center;
  color: #FFF;
  font-family: Nokia-Bold;
  text-transform: capitalize;
  font-size: 1vw;
  line-height: 1.5vw;
}

.speakerCont form .buttonRoom .speaker .info {
  margin: 15px auto;

  display: flex;
  flex-flow: column;

  text-align: center;
}

.speakerCont form .buttonRoom .speaker .info h2 {
  width: 100%;
  margin: auto;

  font-size: 1.2vw;
  line-height: 1.2vw;
  color: #00666F;
}

.speakerCont form .buttonRoom .speaker .info p {
  width: 100%;
  margin: 2px auto;

  font-size: .9vw;
  line-height: .9vw;
  color: #555;
  font-family: Nokia-Bold;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .speakerCont {
    height: max-content;
    max-height: 500px;

    margin: auto;
  }

  .speakerCont form .buttonRoom .speaker .image .title {
    font-size: 2vw;
    line-height: 3vw;
  }

  .speakerCont form .buttonRoom .speaker .info h2 {
    font-size: 2.3vw;
    line-height: 2.3vw;

    margin: 10px auto;
  }

  .speakerCont form .buttonRoom .speaker .info p {
    font-size: 1.6vw;
    line-height: 1.6vw;
  }
}

@media (max-width: 480px) {
  .speakerCont form .buttonRoom .speaker .image .title {
    font-size: 3vw;
    line-height: 3.5vw;
  }

  .speakerCont form .buttonRoom .speaker .info h2 {
    font-size: 3.2vw;
    line-height: 3.2vw;
  }

  .speakerCont form .buttonRoom .speaker .info p {
    font-size: 2vw;
    line-height: 2vw;
  }
}
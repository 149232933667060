.agendaCont {
  width: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.agendaCont .btnMenu {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  display: flex;
  justify-content: space-between;
}

.agendaCont .btnMenu .btnAgenda1 {
  width: 50%;

  margin: auto;
  padding: 10px 30px;

  border: none;
  outline: none;

  text-transform: uppercase;
  font-size: 20px;
  font-family: Nokia-Bold;
  color: #AAA;

  background: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1)  70%, rgba(200, 200, 200, 1) 100%);

  cursor: pointer;
}

.agendaCont .btnMenu .btnAgenda2 {
  width: 50%;

  margin: auto;
  padding: 10px 30px;

  border: none;
  outline: none;

  text-transform: uppercase;
  font-size: 20px;
  font-family: Nokia-Bold;
  color: #AAA;

  background: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(200, 200, 200, 1) 0%, rgba(255, 255, 255, 1) 70%);

  cursor: pointer;
}

.agendaCont .btnMenu .selected {
  background: rgba(255, 255, 255, 1);
  color: #00666F;
}

/* ======================================== */

.agendaCont .imgagendaDia {
  width: 70vw;
  min-height: calc(70vw / 0.59);

  margin: 5% auto;

  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .agendaCont .btnMenu .btnAgenda1 {
    font-size: 4vw;
    line-height: 3.5vw;
  }

  .agendaCont .btnMenu .btnAgenda2 {
    font-size: 4vw;
    line-height: 3.5vw;
  }

  /* ========================================== */

  .agendaCont .imgTitle {
    width: 70vw;
    min-height: 70vw;
  }

  .agendaCont .imgagendaDia {
    width: 110vw;
    min-height: calc(110vw / 0.59);
  }
}

@media screen and (max-width: 480px) {
  .agendaCont .imgTitle {
    width: 120vw;
    min-height: 120vw;
  }

  .agendaCont .imgagendaDia {
    width: 180vw;
    min-height: calc(180vw / 0.59);
  }
}
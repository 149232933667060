.checkBoxContainer {
  position: relative;

  width: 85%;
  margin: 10px auto;
  padding: 10px;

  display: flex;
  flex-flow: row;
}

.checkBoxContainer .textCont {
  font-size: 1vw;
  font-family: Nokia-light;
  font-weight: 300;
  text-decoration: none;
  color: #FFF;
  text-align: start;
}

.checkBoxContainer .customCheckBox {
  position: relative;

  cursor: pointer;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
}

.checkBoxContainer .customCheckBox .checkCont {
  position: relative;
  z-index: 10;
  margin: auto 20px;

  width: 33px;
  min-width: 33px;
  height: 33px;

  display: flex;
}

.checkBoxContainer .customCheckBox .check {
  width: 25px;
  min-width: 25px;
  height: 25px;

  background-color: #FFFFFF00;
  border: 1px solid #FFF;
  border-radius: 3px;

  color: #00717e;

  margin: 0px auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.checkBoxContainer .customCheckBox .ok {
  color: #006671;
  background-color: #FFFFFF;
}

/* ---------------------------- */

.checkBoxContainer .ModalCont {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  flex-flow: column;

  z-index: 1000;
}

.checkBoxContainer .ModalCont .modalstyle {
  position: relative;

  width: 55%;
  height: 75%;

  margin: auto;

  display: flex;
}

.checkBoxContainer .ModalCont .modalInner {
  width: 90%;
  height: 90%;

  background-color: #FFF;
  border-radius: 20px;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;

  z-index: 1010;
}

/* width and height */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}

/* Handle on hover */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

/* ----------------------------------------------- */

.checkBoxContainer .ModalCont .close {
  position: absolute;
  top: 14%;
  right: 23%;

  width: 25px;
  height: 25px;
  min-width: 25px;

  background-color: #00717e;
  border-radius: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #FFF;

  cursor: pointer;

  z-index: 100;
}

.checkBoxContainer .ModalCont .modalInner .textModal {
  width: 80%;

  margin: 30px auto;

  text-align: center;
  color: #222;
  font-family: Nokia-Bold;
}

.checkBoxContainer .ModalCont .modalInner .textModal a {
  text-align: center;
  color: #f8711c;
  font-family: Nokia-Bold;
  text-decoration: none;
}

/* ======================================== */
/* ======================================== */
/* ======================================== */


@media screen and (max-width: 1024px) {
  .checkBoxContainer .textCont {
    font-size: 1.6vw;
    font-weight: 400;
    text-decoration: none;
    color: #FFF;
    text-align: start;
  }

  .checkBoxContainer {
    width: 90%;
    margin: 5px auto;
  }

  .checkBoxContainer .customCheckBox {
    font-size: 14px;
  }

  .checkBoxContainer .ModalCont .close {
    position: absolute;
    top: 12%;
    right: 8%;
  }

  .checkBoxContainer .ModalCont .modalstyle {
    width: 85%;
    height: 75%;
  }

  .checkBoxContainer .ModalCont .modalInner h2 {
    font-size: 5.5vw;
  }

  .checkBoxContainer .ModalCont .modalInner .textModal {
    font-size: 2.3vw;
    width: 95%;
  }
}

@media screen and (max-width: 640px) {
  .checkBoxContainer .textCont {
    font-size: 2.6vw;
    font-weight: 400;
    text-decoration: none;
    color: #FFF;
    text-align: start;
  }

  .checkBoxContainer .ModalCont .modalstyle {
    width: 95%;
    height: 75%;
  }

  .checkBoxContainer .ModalCont .modalInner h2 {
    font-size: 6.5vw;
  }

  .checkBoxContainer .ModalCont .modalInner .textModal {
    font-size: 3vw;
  }
}

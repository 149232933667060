.agendaContainer {
  width: 35%;
  min-width: 200px;
  max-height: 35vh;

  margin: auto auto 2% 0%;
  padding-bottom: 5px;

  display: flex;
  flex-flow: column;

  border-radius: 8px;

  background-color: #FFF;
  box-shadow: 10px 10px 15px #777;
}

.agendaContainer .titleCont {
  width: 100%;

  background-color: #206068;
  border-radius: 5px;
}

.agendaContainer .titleCont h2 {
  width: 100%;

  font-size: 1.2vw;
  line-height: 1.2vw;
  letter-spacing: 3px;
  font-family: Nokia-Ultra-light;
  color: #FFF;

  margin: auto;
  padding: 3% 10%;
}

/* ----------------------------------- */

.agendaContainer .content {
  width: 95%;
  max-height: 30vh;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

.agendaContainer .content::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.agendaContainer .content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.agendaContainer .content::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}
.agendaContainer .content::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

/* ----------------------- */

.agendaContainer .content .Cont {
  width: 100%;

  margin: 5px auto;
  padding: 10px 0px;

  display: flex;
  flex-flow: column;

  background-color: #FFF;
  border: none;
  border-bottom: 1px solid #DDD;
}

.agendaContainer .content .Cont p {
  width: 90%;

  color: #206068;
  font-size: 1vw;
  line-height: 1vw;

  margin: auto;
}

.agendaContainer .content .Cont .time {
  font-family: Nokia-Bold;
  font-size: .8vw;
  line-height: .8vw;
  text-transform: uppercase;
}

.agendaContainer .content .Cont h2 {
  width: 90%;

  color: #f8711c;
  font-size: 1.2vw;
  line-height: 1.2vw;
  letter-spacing: 2px;
  font-family: Nokia-Extra-Bold;

  margin: 2% auto;
}

.agendaContainer .content .Cont .separator {
  width: 25%;
  margin: 5px 5%;
  background-color: #00666F;
}

.agendaContainer .content .Cont .description {
  margin: 2% auto;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .agendaContainer {
    width: 40%;
    min-width: 200px;

    margin: auto;
  }

  .agendaContainer .titleCont h2 {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .agendaContainer .content .Cont p {
    font-size: 2.9vw;
    line-height: 2.9vw;
  }

  .agendaContainer .content .Cont .time {
    font-family: Nokia-Bold;
    font-size: 2.2vw;
    line-height: 2.2vw;

    margin: 10px auto;
  }

  .agendaContainer .content .Cont h2 {
    font-size: 3.2vw;
    line-height: 3.2vw;
  }
}

@media (max-width: 768px) {
  .agendaContainer {
    width: 40%;
    min-width: 200px;

    margin: 10px;
  }

  .agendaContainer .titleCont h2 {
    font-size: 3.2vw;
    line-height: 3.2vw;
  }
  .agendaContainer .content .Cont p {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  .agendaContainer .content .Cont .time {
    font-family: Nokia-Bold;
    font-size: 2vw;
    line-height: 2vw;

    margin: 10px auto;
  }

  .agendaContainer .content .Cont h2 {
    font-size: 3.2vw;
    line-height: 3.2vw;
  }
}

@media (max-width: 480px) {
  .agendaContainer {
    width: 100%;
    min-width: 200px;

    margin: 10px;
  }

  .agendaContainer .titleCont h2 {
    font-size: 5vw;
    line-height: 5vw;
  }
  .agendaContainer .content .Cont p {
    font-size: 4vw;
    line-height: 4vw;
  }

  .agendaContainer .content .Cont .time {
    font-family: Nokia-Bold;
    font-size: 4vw;
    line-height: 4vw;

    margin: 10px auto;
  }

  .agendaContainer .content .Cont h2 {
    font-size: 6vw;
    line-height: 6vw;
  }
}
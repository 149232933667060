.modalContainer {
  position: fixed;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .5);

  display: flex;

  z-index: 10000;
}

.modalContainer .scrollCont {
  position: relative;

  width: 25vw;
  max-height: 80vh;

  margin: auto;

  overflow-x: hidden;
  overflow-y: auto;

  display: flex;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

/* width and height */
.modalContainer .scrollCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.modalContainer .scrollCont::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.modalContainer .scrollCont::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}

/* Handle on hover */
.modalContainer .scrollCont::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.modalContainer .modalContent {
  width: 90%;

  margin: auto;

  background-color: #FFF;
  border-radius: 3vw;

  display: flex;
  flex-flow: column;
}

.modalContainer .close {
  position: absolute;
  top: 10vh;
  right: 35vw;

  background-color: transparent;
  border: none;

  font-family: Nokia-Extra-Bold;
  color: #FFF;
  font-size: 1.8vw;

  cursor: pointer;
}

.modalContainer .modalContent .titlePopup {
  background-color: #8DBA38;
  border-radius: 3vw 3vw 0vw 0vw;

  padding: 0px 10px;

  display: flex;
  flex-flow: column;

  color: #FFF;
  font-family: Nokia-Bold;
  letter-spacing: 2px;
  text-align: center;
}

.modalContainer .modalContent .titlePopup p {
  width: 100%;

  margin: 10px auto;

  font-size: 1vw;
  line-height: 1vw;
}

.modalContainer .modalContent .titlePopup h2 {
  width: 100%;

  margin: 5px auto 10px auto;

  font-size: 1.5vw;
  line-height: 1.5vw;
}

/* ----------------------------- */

.modalContainer .modalContent form {
  width: 100%;

  display: flex;
}

.modalContainer .modalContent form .buttonRoom {
  width: 100%;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  display: flex;
}

.modalContainer .modalContent form .buttonRoom:hover {
  background-color: #EEE;
}

.modalContainer .modalContent form:last-child .buttonRoom:hover {
  background-color: #EEE;
  border-radius: 0vw 0vw 3vw 3vw;
}

.modalContainer .modalContent .salaCont {
  width: 90%;

  display: flex;
  flex-flow: column;

  margin: 10px auto;
}

.modalContainer .modalContent .salaCont .title {
  width: 100%;

  margin: 10px auto;

  text-align: center;
  color: #8DBA38;
  font-family: Nokia-Bold;
  letter-spacing: 2px;
  text-transform: capitalize;
  font-size: 1vw;
  line-height: 1vw;
}

.modalContainer .modalContent .salaCont h2 {
  width: 100%;

  margin: 5px auto;

  text-align: center;
  color: #00666F;
  font-family: Nokia-Extra-Bold;
  letter-spacing: 2px;
  font-size: 1.2vw;
  line-height: 1.2vw;
}

.modalContainer .modalContent .salaCont .subtitle {
  width: 100%;

  margin: 5px auto;

  text-align: center;
  color: #999;
  font-family: Nokia-Bold;
  letter-spacing: 2px;
  font-size: .9vw;
  line-height: .9vw;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .modalContainer .scrollCont {
    width: 65vw;
  }

  .modalContainer .close {
    font-size: 3.8vw;

    top: 10vh;
    right: 15vw;
  }

  .modalContainer .modalContent .titlePopup p {
    font-size: 3vw;
    line-height: 3vw;
  }

  .modalContainer .modalContent .titlePopup h2 {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }

  .modalContainer .modalContent .salaCont .title {
    font-size: 3.3vw;
    line-height: 3.3vw;
  }

  .modalContainer .modalContent .salaCont h2 {
    font-size: 3.8vw;
    line-height: 3.8vw;
  }

  .modalContainer .modalContent .salaCont .subtitle {
    font-size: 3vw;
    line-height: 3vw;
  }
}
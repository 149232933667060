.certifiedCont {
  width: 100%;

  margin: auto;

  display: flex;
}

.certifiedCont .modalCont {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;

  background-color: #0006;
}

.certifiedCont .modalCont .close {
  position: absolute;
  top: 2%;
  right: 2%;

  width: 3vw;
  height: 3vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00677F;
  border-radius: 200px;
  color: #FFF;

  cursor: pointer;

  z-index: 100;
}

.certifiedCont .modalCont .content {
  width: 95%;
  height: 95%;
  max-height: 95vh;

  background-color: #FFF;
  border-radius: 10px;

  display: flex;
  flex-flow: column;

  margin: auto;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00677F #002735;
}
.certifiedCont .modalCont .content::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.certifiedCont .modalCont .content::-webkit-scrollbar-track {
  background: #FFF0;
}
.certifiedCont .modalCont .content::-webkit-scrollbar-thumb {
  background: #00677F;
}
.certifiedCont .modalCont .content::-webkit-scrollbar-thumb:hover {
  background: #002735;
}

.certifiedCont .modalCont .content .actions {
  width: 30%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: auto;
}

.certifiedCont .modalCont .content .actions div {
  padding: 3px 4%;

  background-color: #00677F;
  color: #FFF;
  border-radius: 100PX;

  cursor: pointer;

  transition: .2s;
}
.certifiedCont .modalCont .content .actions div:hover {
  background-color: #002735;
}
.certifiedCont .modalCont .content .actions div:active {
  background-color: #FF066C;
}

.certifiedCont .modalCont .content .actions .left::before {
  content: '❮';
}
.certifiedCont .modalCont .content .actions .right::before {
  content: '❯';
}

.certifiedCont .modalCont .content .generateBtn {
  margin: 10px auto;
  padding: 5px 10%;

  background-color: #FF066C;
  border-radius: 10px;
  border: none;
  outline: none;

  color: #FFF;
  font-size: 1vw;

  cursor: pointer;
}

.certifiedCont .modalCont .content .pdfContent {
  position: relative;

  width: 100%;

  margin: 10px auto 50px auto;

  display: flex;

  z-index: 1;
}

.certifiedCont .modalCont .content .pdfContent .image {
  width: 90vw;
  height: calc(90vw / 1.77777);

  margin: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../../img/CERTIFICADO.jpg');
}

.certifiedCont .modalCont .content .pdfContent .name {
  position: absolute;
  top: 43%;
  left: 50%;

  width: 90%;

  transform: translate(-48%);

  color: #ff7c2a;
  font-family: Nokia-Bold;
  font-size: 4vw;
  line-height: 4VW;
  text-transform: uppercase;
  text-align: center;

  z-index: 1000;
}

/* ============================================================ */

.certifiedCont .loadNameCont {
  width: 40%;

  margin: auto;

  display: flex;
  flex-flow: column-reverse;
}

.certifiedCont .loadNameCont .inputNames {
  width: 90%;
  min-width: 90%;
  max-width: 90%;

  min-height: 60px;
  max-height: 350px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #FFF;
  box-shadow: 3px 3px 10px #CCC;

  margin: auto;
  padding: 10px 5%;

  font-size: 1.2vw;
  text-align: center;
}

.certifiedCont .loadNameCont .inputNames::placeholder {
  color: #CCC;
}

.certifiedCont .loadNameCont .loadNames {
  margin: 10px auto;
  padding: 5px 5%;

  background-color: #FF066C;
  border-radius: 10px;
  border: none;
  outline: none;

  color: #FFF;
  font-size: 1vw;

  cursor: pointer;
}

/* ============================================================= */

.certifiedCont .generatorCont {
  width: 50%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.certifiedCont .generatorCont .generateBtn {
  margin: 10px auto;
  padding: 5px 5%;

  background-color: #FF066C;
  border-radius: 10px;
  border: none;
  outline: none;

  color: #FFF;
  font-size: 1vw;

  cursor: pointer;
}

.certifiedCont .generatorCont .generatedCertCont {
  width: 100%;
  height: 400px;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00677F #002735;
}

.certifiedCont .generatorCont .generatedCertCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.certifiedCont .generatorCont .generatedCertCont::-webkit-scrollbar-track {
  background: #FFF0;
}
.certifiedCont .generatorCont .generatedCertCont::-webkit-scrollbar-thumb {
  background: #00677F;
}
.certifiedCont .generatorCont .generatedCertCont::-webkit-scrollbar-thumb:hover {
  background: #002735;
}

.certifiedCont .generatorCont .generatedCertCont .userCont {
  width: 90%;

  margin: 10px auto;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #002735;
}

.certifiedCont .generatorCont .generatedCertCont .userCont .userName {
  margin: 5px auto;
  font-size: 1.4vw;
  color: #00677F;
}

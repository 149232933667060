.BBBCont {
  width: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.BBBCont .warn {
  font-size: 1.3vw;
  color: #FF066C;
  text-align: center;

  margin: 10px auto;
}

.BBBCont .day {
  width: 90%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.BBBCont .day button {
  margin: 10px auto;
  padding: 5px 5%;

  background-color: #FF066C;
  border-radius: 10px;
  border: none;
  outline: none;

  color: #FFF;
  font-size: 1.5vw;

  cursor: pointer;
}

.BBBCont .day .listContainer {
  width: 90%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.BBBCont .day .listContainer .header {
  width: 100%;

  margin: 20px auto;

  display: flex;

  border-bottom: 1px solid #AAA;
}

.BBBCont .day .listContainer .header h2 {
  margin: auto;
}

.BBBCont .day .listContainer .infoCont {
  width: 100%;
  height: 300px;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00677F #002735;
}

.BBBCont .day .listContainer .infoCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.BBBCont .day .listContainer .infoCont::-webkit-scrollbar-track {
  background: #FFF0;
}
.BBBCont .day .listContainer .infoCont::-webkit-scrollbar-thumb {
  background: #00677F;
}
.BBBCont .day .listContainer .infoCont::-webkit-scrollbar-thumb:hover {
  background: #002735;
}

.BBBCont .day .listContainer .infoCont .item {
  width: 90%;

  margin: 5px auto;

  display: flex;
  align-items: center;
}

.BBBCont .day .listContainer .infoCont .item h4 {
  margin: auto;
}
.loginContainer {
  width: 100vw;
  height: 100vh;

  display: flex;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/login/backgroundImg.png');

  overflow: hidden;
}

.loginContainer .container {
  width: 100%;
  height: 100%;

  display: flex;

  scrollbar-width: thin;
  scrollbar-color: #006671 transparent;

  overflow-x: hidden;
  overflow-y: auto;
}

/* width and height */
.loginContainer .container::-webkit-scrollbar {
  width: 8px; height: 8px;
}

/* Track */
.loginContainer .container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.loginContainer .container::-webkit-scrollbar-thumb {
  background: #006671;
}

/* Handle on hover */
.loginContainer .container::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.loginContainer .soporte {
  position: absolute;
  bottom: 2vw;
  right: 2vw;

  margin: auto;
  padding: 10px 20px;

  text-transform: uppercase;
  text-decoration: none;
  color: #FFF;
  font-family: Nokia-Bold;
  font-weight: 600;

  background-color: #006671;
  box-shadow: 3px 5px 3px #00232e;
  border-radius: 10px;
}

.loginContainer .soporte::before {
  content: "Soporte";
}

/* --------------------------------------------- */

.loginContainer .designs {
  width: 40%;
  height: 100%;
  min-height: 450px;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.loginContainer .designs .logoFaes {
  width: 25vw;
  height: calc(25vw / 3.90769);

  margin: auto;

  background-image: url('../../img/login/logoFarma.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.loginContainer .designs .logoD {
  width: 20vw;
  height: calc(20vw / 1.31901);

  margin: auto;

  background-image: url('../../img/login/logoD.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.loginContainer .designs .hidrofe {
  width: 20vw;
  height: calc(20vw / 3.9767);

  margin: auto;

  background-image: url('../../img/login/hidroferol.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

/* ----------------------------------------- */

.loginContainer .forms {
  width: 40%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .loginContainer .container {
    display: flex;
    flex-flow: column;
  }

  .loginContainer .designs {
    width: 80%;
    height: auto;
    min-height: auto;
    margin: 10px auto 0 auto;
    display: flex;
    flex-flow: column;
    backdrop-filter: blur(2px);
  }

  .loginContainer .designs .logoFaes {
    width: 35vw;
    height: calc(35vw / 3.90769);

    margin: 5px auto;
  }

  .loginContainer .designs .logoD {
    width: 25vw;
    height: calc(25vw / 1.31901);

    margin: 10px auto;
  }

  .loginContainer .designs .hidrofe {
    width: 35vw;
    height: calc(35vw / 3.9767);

    margin: 20px auto;
  }

  .loginContainer .forms {
    width: 90%;
    margin: 20px auto auto auto;
  }

  .loginContainer .soporte {
    font-size: 1.5vw;
  }

  .loginContainer .soporte::before {
    content: "Soporte";
  }
}

@media (max-width: 640px) {
  .loginContainer .soporte {

    font-size: 2.5vw;
  }

  .loginContainer .designs .logoFaes {
    width: 50vw;
    height: calc(50vw / 3.90769);

    margin: 5px auto;
  }

  .loginContainer .designs .logoD {
    width: 30vw;
    height: calc(30vw / 1.31901);

    margin: 10px auto;
  }

  .loginContainer .designs .hidrofe {
    width: 50vw;
    height: calc(50vw / 3.9767);

    margin: 20px auto;
  }
}
.roomContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/room/fondoStream.png');
}

.roomContainer .menu {
  width: 90%;

  margin: 0 auto;
  padding: .5% 5%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roomContainer .back {
  border: none;
  outline: none;
  border-radius: 200px;

  padding: 10px 20px;

  background-color: #00666F;
  box-shadow: 3px 5px 10px #777;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Nokia-Bold;

  z-index: 10;

  cursor: pointer;
}

.roomContainer .agenda {
  border: none;
  outline: none;
  border-radius: 200px;

  padding: 10px 2vw;

  background-color: #00666F;
  box-shadow: 3px 5px 10px #777;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Nokia-Bold;

  z-index: 10;

  cursor: pointer;
}

.roomContainer .sections {
  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

/* width and height */
.roomContainer .sections::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.roomContainer .sections::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.roomContainer .sections::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}

/* Handle on hover */
.roomContainer .sections::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.roomContainer .sections .stream {
  position: relative;

  width: 65vw;
  height: 90%;
  min-height: 600px;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.roomContainer .sections .stream .logos {
  width: 35vw;
  height: calc(35vw / 6.58793);

  margin: 10px auto 0vh 5vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/room/logosStream.png');
}

.roomContainer .sections .stream .player {
  margin: auto;

  display: flex;

  overflow: hidden;
}

.roomContainer .sections .stream .player .iframeCont {
  width: 60vw;
  height: calc(60vw / 1.7777);

  margin: auto;

  display: flex;

  overflow: hidden;
}

.roomContainer .sections .stream .player iframe {
  width: 99.5%;
  height: 99.8%;

  margin: auto;

  border: none;
  outline: none;
}

/* =================================================== */

.roomContainer .sections .interactions {
  position: relative;

  width: 35vw;
  height: 90%;
  min-height: 650px;

  min-width: 300px;

  margin: auto auto 30px auto;

  display: flex;
  flex-flow: column;

  border: none;
}

.roomContainer .sections .interactions .title {
  width: 100%;
  display: flex;
  flex-flow: column;

  margin: auto auto 10px auto;
}

.roomContainer .sections .interactions .title h1 {
  width: 90%;
  text-align: end;
  font-family: Nokia-Bold;
  color: #206068;
  font-size: 1.5vw;
  margin: auto;
}

.roomContainer .sections .interactions .title h2 {
  width: 90%;
  text-align: end;
  font-family: Nokia-Bold;
  color: #ff7723;
  font-size: 2vw;
  margin: auto;
}

/* --------------------------------- */

.roomContainer .sections .interactions .kahootCont {
  width: 95%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;
}

.roomContainer .sections .interactions .kahootCont .kbtn,
.roomContainer .sections .interactions .kahootCont .chatBtn {
  border: none;
  outline: none;
  border-radius: 5px 5px 0px 0px;

  padding: 5px 2vw;
  margin: auto;

  background-color: #00232e;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Nokia-Bold;
  font-size: 1.2vw;

  z-index: 10;

  cursor: pointer;
}

.kbtn {
  background: rgb(0,35,46);
  background: linear-gradient(90deg, rgba(0,35,46,1) 0%, rgba(0,102,111,1) 76%);
}

.chatBtn {
  background: rgb(0,35,46);
  background: linear-gradient(270deg, rgba(0,35,46,1) 0%, rgba(0,102,111,1) 76%);
}

.roomContainer .sections .interactions .kahootCont .clicked,
.roomContainer .sections .interactions .kahootCont .clicked {
  background: #00666F;
}

.roomContainer .sections .interactions .kahootCont .kbtn:disabled,
.roomContainer .sections .interactions .kahootCont .chatBtn:disabled {
  border: none;
  outline: none;
  border-radius: 5px 5px 0px 0px;

  padding: 5px 2vw;
  margin: auto auto 0 auto;

  background: #999;
  color: #EEE;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Nokia-Bold;
  font-size: 1.2vw;

  z-index: 10;

  cursor: default;
}

.roomContainer .sections .interactions .kahootCont .iK{
  width: 100%;
  height: 65vh;
  min-height: 450px;

  margin: 0px auto 10px auto;
}

/* --------------------------------- */

.roomContainer .sections .interactions .salasBtn {
  width: 95%;

  margin: 0px auto 30px auto;

  display: flex;
  align-items: center;
}

.roomContainer .sections .interactions .salasBtn p {
  color: #00232e;
  font-family: Nokia-Bold;
  font-size: 2.5vw;
  margin: auto;
}

.roomContainer .sections .interactions .salasBtn .salas {
  border: none;
  outline: none;
  border-radius: 200px;

  padding: 10px 3vw;
  margin: auto 1% auto auto;

  background-color: #00666F;
  box-shadow: 3px 5px 10px #555;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Nokia-Bold;
  font-size: 1.2vw;

  z-index: 10;

  cursor: pointer;
}

/* ============================================= */

.roomContainer .modalContainerA {
  position: fixed;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .5);

  display: flex;

  z-index: 10000;
}

.roomContainer .modalContainerA .scrollCont {
  position: relative;

  min-width: 90vw;
  max-width: 95vw;
  max-height: 80vh;

  margin: auto;

  display: flex;
}

.roomContainer .modalContainerA .modalContent {
  min-width: 90vw;
  max-height: 80vh;

  margin: auto;
  padding: 10px 2vw;

  background-color: #FFF;
  border-radius: 2vw;

  display: flex;
  flex-flow: row;

  text-transform: uppercase;

  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

.roomContainer .modalContainerA .modalContent::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.roomContainer .modalContainerA .modalContent::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.roomContainer .modalContainerA .modalContent::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}
.roomContainer .modalContainerA .modalContent::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.roomContainer .modalContainerA .close {
  position: absolute;
  top: 35vh;
  right: 24vw;

  background-color: transparent;
  border: none;

  font-family: Nokia-Extra-Bold;
  color: #FFF;
  font-size: 1.8vw;

  cursor: pointer;
}



/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .roomContainer .back {
    padding: 10px 4vw;

    font-size: 1.5vw;
  }

  .roomContainer .agenda {
    padding: 10px 4vw;

    font-size: 1.5vw;
  }

  .roomContainer .sections {
    display: flex;
    flex-flow: column;
    margin: auto;
    height: auto;
  }

  .roomContainer .sections .stream {
    position: relative;

    width: 95vw;
    height: auto;
    min-height: max-content;

    margin: auto;
  }

  .roomContainer .sections .stream .logos {
    width: 60vw;
    min-height: calc(60vw / 6.58793);
    margin: 5px auto 5px 2vw;
  }

  .roomContainer .sections .stream .player .iframeCont {
    width: 95vw;
    height: calc(95vw / 1.7777);
  }

  .roomContainer .sections .interactions .kahootCont .kbtn,
  .roomContainer .sections .interactions .kahootCont .chatBtn {
    font-size: 2.2vw;
  }
  .roomContainer .sections .interactions .kahootCont .kbtn:disabled,
  .roomContainer .sections .interactions .kahootCont .chatBtn:disabled {
    font-size: 2.2vw;
  }

  /* ------------------------------------------------ */

  .roomContainer .sections .interactions {
    width: 100vw;
    height: 65vh;
    min-height: 850px;
    min-width: 250px;

    margin: 20px auto;
  }


  .roomContainer .sections .interactions .title {
    width: 100%;
    display: flex;
    flex-flow: column;

    margin: 20px auto;
  }

  .roomContainer .sections .interactions .title h1 {
    width: 90%;
    font-size: 3.5vw;
  }

  .roomContainer .sections .interactions .title h2 {
    width: 90%;
    font-size: 3.5vw;
  }

  .roomContainer .sections .interactions .kahootCont {
    width: 80vw;

    display: flex;
    flex-flow: column;

    margin: auto;
  }

  .roomContainer .sections .interactions .kahootCont .iK{
    width: 100%;
    height: 60vh;
  }

  .roomContainer .sections .interactions .salasBtn {
    width: 80%;
    margin: 0px auto 20px auto;
  }

  .roomContainer .sections .interactions .salasBtn p {
    color: #00232E;
    font-family: Nokia-Bold;
    font-size: 4.5vw;
    margin: auto;
  }

  .roomContainer .sections .interactions .salasBtn .salas {
    font-size: 2.2vw;
  }
}

@media (max-width: 768px) {
  .roomContainer .menu {
    padding: 1% 5%;
  }

  .roomContainer .back {
    padding: 10px 4vw;

    font-size: 3vw;
    letter-spacing: 2px;
  }

  .roomContainer .agenda {
    padding: 10px 4vw;

    font-size: 3vw;
    letter-spacing: 2px;
  }

  .roomContainer .sections .interactions .title h1 {
    width: 90%;
    font-size: 4.5vw;
  }

  .roomContainer .sections .interactions .title h2 {
    width: 90%;
    font-size: 4.5vw;
  }

  .roomContainer .sections .interactions .salasBtn .salas {
    font-size: 4.2vw;
    padding: 10px 6vw;
  }
}
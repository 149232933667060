.formContainer {
  width: 70%;

  display: flex;

  margin: auto;
  padding: 5vw 2vw;

  background-color: #006671;
  box-shadow: 10px 10px 10px #555;
}

.formContainer .form {
  position: relative;

  width: 100%;

  display: flex;
  flex-flow: column;
}

.formContainer .form h1 {
  width: 90%;

  margin: auto;

  font-size: 2.3vw;
  line-height: 2.3vw;
  color: #FFF;
  font-family: Nokia-Bold;
}

.formContainer .form .subtitle {
  width: 90%;

  margin: auto;
  margin-bottom: 2vw;

  font-size: 2.1vw;
  line-height: 2.1vw;
  color: #D87233;
  font-family: Nokia-Bold;
}

.formContainer .loanding {
  width: 20vw;
  height: 20vw;

  margin: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 10;

  animation: rotate infinite 1.5s linear;
  animation-play-state: running;
  -moz-animation-play-state:running;
}
.formContainer .loanding:hover {
  animation: rotate infinite 1s linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formContainer .form input {
  width: 50%;

  margin: 10px auto;
  padding: 10px 3vw;

  border-radius: 200px;
  border: none;
  outline: none;

  text-align: center;
  font-size: 1.2vw;
  color: #111;
  font-weight: 300;
  font-family: Nokia-light;

  z-index: 10;
}

.formContainer .form input::placeholder {
  color: #999;
}

/* ======================================= */

.formContainer .form .btnSubmit {
  width: 65%;

  background-color: #3DBD08;
  box-shadow: 0px 6px 2px #044805;
  border: none;
  outline: none;
  border-radius: 100px;

  color: #FFF;
  font-size: 1.4vw;
  font-weight: 100;
  font-family: Nokia-Bold;

  margin: 10px auto;
  padding: 10px 3vw;

  cursor: pointer;
}

/* ============================================= */
/* ============================================= */

.formContainer .errorMsg {
  width: 60%;

  margin: auto;
  padding: 20px;

  background-color: #006671;
  border-radius: 20px;

  display: flex;
  flex-flow: column;
}

.formContainer .errorMsg h2 {
  margin: auto;

  text-align: center;
  font-size: 3vw;
  text-transform: uppercase;
  color: #FFF;
  text-shadow: 0px 0px 5px #FFF;
}

.formContainer .errorMsg .back {
  padding: 10px 30px;
  margin: 20px auto;

  background-color: #FFF;
  border-radius: 10px;
  border: none;
  outline: none;

  color: #006671;
  font-weight: 600;
  font-size: 2vw;

  cursor: pointer;
}

/* ====================================== */
/* ====================================== */
/* ====================================== */


@media (max-width: 1024px) {
  .formContainer {
    width: 90%;
  }

  .formContainer .form h1 {
    font-size: 4.3vw;
    line-height: 4.3vw;
  }

  .formContainer .form .subtitle {
    margin-bottom: 2vw;

    font-size: 4.1vw;
    line-height: 4.1vw;
  }

  .formContainer .form input {
    width: 55.5%;
    padding: 10px 3vw;
    font-size: 2.6vw;
  }

  .formContainer .form .btnSubmit {
    font-size: 2.7vw;
  }
}

@media (max-width: 640px) {
  .formContainer .form h1 {
    font-size: 6.3vw;
    line-height: 6.3vw;
  }

  .formContainer .form .subtitle {
    margin-bottom: 2vw;

    font-size: 6.1vw;
    line-height: 6.1vw;
  }

  .formContainer .form input {
    width: 60%;
    padding: 5px 3vw;
    font-size: 4vw;
    line-height: 4vw;
  }

  .formContainer .form .btnSubmit {
    font-size: 4vw;
  }
}